import * as React from "react";
import "swiper/css/autoplay";
import "swiper/css/mousewheel";
import "swiper/css/scrollbar";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/effect-coverflow";
// import { Scrollbar, Mousewheel, Autoplay } from 'swiper';
import { EffectCoverflow, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

export default function ({ images, props }) {
  const imagenes = images;
  
  return (
    <section className="pt-[1rem] w-full pb-[3rem">
        {/* <Swiper
          modules={[Scrollbar, Mousewheel, Autoplay]}
          loop={true}
          pagination={{ clickable: true }}
          centeredSlides={true}
          grabCursor={true}
          scrollbar={{ draggable: true }}
          mousewheel={{
            invert: false,
          }}
          slidesPerView={3}
          spaceBetween={8}
          autoplay={{
            delay: 5000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          }}
        > */}
        <Swiper
          modules={[EffectCoverflow, Pagination, Autoplay]}
          effect={"coverflow"}
          loop={true}
          slidesPerView={3}
          pagination={{
            clickable: true,
          }}
          centeredSlides={true}
          grabCursor={true}
          coverflowEffect={{
            rotate: 0, // Slide rotate in degrees
            stretch: 36, // Stretch space between slides (in px)
            modifier: 1, // Effect multipler
            slideShadows: true,
          }}
          autoplay={{
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          }}
          breakpoints={{
            400:{
              slidesPerView:1,
            },
            639: {
              slidesPerView: 1,
            },
            865:{
              slidesPerView:2
            },
            1000:{
              slidesPerView:2
            }
          }}
        >
          {props.map((p, index) => {
            return (
              <SwiperSlide key={index}>
                <Link to={`/Eventos/${p.id}`}>
                <img
                  src={p.imagenes}
                  alt=""
                  className="w-[851px] h-[700px] rounded-2xl"
                  />
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
    </section>
  );
}
