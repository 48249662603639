import React, { useEffect } from "react";
import Carousel from "./Carousel";
import Sponsors from "./Sponsors";
import InfoCarrera from "./InfoCarrera";
import { useDispatch, useSelector } from "react-redux";
import { getInfoCarrera } from "../redux/actions/index";


const Home = () => {
  console.log('Auth0 Domain:', process.env.REACT_APP_AUTH0DOMAIN);
console.log('Auth0 Client ID:', process.env.REACT_APP_AUTH0CLIENTID);
  const dispatch = useDispatch();
  const props = useSelector((state) => state.carreras);
  const imagenes = useSelector((state) => state.imagenes);

  useEffect(() => {
    dispatch(getInfoCarrera());
  }, [dispatch]);

  return (
    <div className="h-full flex flex-col items-center justify-center bg-no-repeat bg-cover">
      <Carousel images={imagenes} props={props} className="w-full max-w-6xl mx-auto" />
      <section className="w-full pt-8 md:pt-16 lg:py-22">
        <div className="flex flex-col items-center justify-center space-y-4 text-center">
          <h2 className="text-3xl font-bold flex justify-center tracking-tighter sm:text-5xl">
            Próximas Carreras
          </h2>
          <div className="space-y-2 flex justify-center">
            <p className="max-w-[900px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
              Explora nuestras carreras de vanguardia y descubre tu vocación.
            </p>
          </div>
          <div className="mx-auto grid max-w-5xl items-center gap-6 py-12 lg:grid-cols-2 lg:gap-12">
            {props &&
              props.map((e,i) => {
                return <InfoCarrera props={e} key={i} />;
              })}
          </div>
        </div>
      </section>
      {/* <Testimonios/> */}
      <Sponsors />
    </div>
  );
};

export default Home;
