import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCorredor, getResDni } from "../redux/actions";
import Lista from "./Lista";
import primero from "../assets/1ro.png";
import coronamasc from "../assets/1ro-gralMasc.png";
import coronafem from "../assets/1ro-gralFem.png";
import segundo from "../assets/2do.png";
import tercero from "../assets/3ro.png";
import Ticket1 from "./Ticket1";

const Resultados = () => {
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const resultados = useSelector((state) => state.resultado);
  const corredor = useSelector((state) => state.corredor);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (user?.email) {
      dispatch(getCorredor(user.email));
    }
  }, [dispatch, user.email]);

  useEffect(() => {
    if (corredor?.dni) {
      dispatch(getResDni(corredor.dni));
    }
  }, [dispatch, corredor.dni]);

  return (
    <section className="container mx-auto px-4 py-8">
      <h2 className="text-lg font-medium">Resultados</h2>
      {showModal ? (
        <button onClick={() => setShowModal(false)} className="text-black text-2xl">
          <Ticket1 id={resultados[0]?.id} />
        </button>
      ) : (
        <div className="mt-6 overflow-x-auto">
          <table className="min-w-full bg-black border border-gray-200">
            <thead>
              <tr className="bg-black">
                {["Nombre", "Tiempo", "Dorsal", "Pos. General", "Pos. Sexo", "Pos. Categoría", "Categoría", "Localidad", "Carrera"].map((header) => (
                  <th key={header} className="py-3 px-2 text-sm font-normal text-left text-white">{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {resultados.length > 0 ? (
                resultados.map((e, i) => <Lista key={i} e={e} i={i} />)
              ) : (
                <tr>
                  <td colSpan="9" className="text-center text-white py-4">No hay resultados disponibles</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </section>
  );
};

export default Resultados;