import React from "react";
import { Link } from "react-router-dom";
import primero from "../assets/1ro.png";
import segundo from "../assets/2do.png";
import tercero from "../assets/3ro.png";
import coronamasc from "../assets/1ro-gralMasc.png";
import coronafem from "../assets/1ro-gralFem.png";

const Lista = ({ e, i }) => {
  let variable = e?.categoria;
  

  return (
    <tr key={i} className="text-white">
      <td className="px-2 py-4 text-sm font-medium whitespace-nowrap">
        <Link to={`/ticket/${e.id}`}>
          <div>
            <h2 className="font-medium text-white">
              {e?.posicionGeneral === "1" ? (
                variable.includes("Masculino") ? (
                  <div className="inline p2-3 py-1">
                    
                    {e?.nombreApellido}
                    <img
                      src={coronamasc}
                      className="h-[20px] w-[20px] inline mr-3"
                      alt="1ro"
                    />
                  </div>
                ) : variable.includes("Femenino") ? (
                  <div className="inline p2-3 py-1">
                    <img
                      src={coronafem}
                      className="h-[20px] w-[20px] inline mr-3"
                      alt="1ro"
                    />
                    {e?.nombreApellido}
                  </div>
                ) : (
                  <div className="inline p2-3 py-1">{e?.nombreApellido}</div>
                )
              ) : (
                <div className="inline p2-3 py-1">{e?.nombreApellido}</div>
              )}
            </h2>
          </div>
        </Link>
      </td>
      <td className="px-2 py-4 text-sm font-medium whitespace-nowrap">
        <div className="inline px-2 py-1 text-sm font-normal rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 dark:bg-gray-800">
          {e?.total}
        </div>
      </td>
      <td className="px-2 py-4 text-sm whitespace-nowrap">{e?.dorsal}</td>
      <td className="px-2 py-4 text-sm whitespace-nowrap">
        {e?.posicionGeneral === "1" ? (
          <div className="inline px-2 py-1">
            <img src={primero} className="h-[20px] w-[20px] inline" alt="1ro" />
            {e?.posicionGeneral}
          </div>
        ) : e?.posicionGeneral === "2" ? (
          <div className="inline px-2 py-1">
            <img src={segundo} className="h-[20px] w-[20px] inline" alt="2do" />
            {e?.posicionGeneral}
          </div>
        ) : e?.posicionGeneral === "3" ? (
          <div className="inline px-2 py-1">
            <img src={tercero} className="h-[20px] w-[20px] inline" alt="3ro" />
            {e?.posicionGeneral}
          </div>
        ) : (
          <div className="inline px-2 py-1">{e?.posicionGeneral} </div>
        )}
      </td>
      <td className="px-2 py-4 text-sm whitespace-nowrap">
        {e?.posicionSexo === "1" ? (
          <div className="inline px-2 py-1">
          
            <img src={primero} className="h-[20px] w-[20px] inline" alt="1ro" />
            {e?.posicionSexo}
          </div>
        ) : e?.posicionSexo === "2" ? (
          <div className="inline px-2 py-1">
            <img src={segundo} className="h-[20px] w-[20px] inline" alt="2do" />
            {e?.posicionSexo}
          </div>
        ) : e?.posicionSexo === "3" ? (
          <div className="inline px-2 py-1">
            <img src={tercero} className="h-[20px] w-[20px] inline" alt="3ro" />
            {e?.posicionSexo}
          </div>
        ) : (
          <div className="inline px-2 py-1">{e?.posicionSexo} </div>
        )}
      </td>
      <td className="px-2 py-4 text-sm whitespace-nowrap">
        {e?.posicionCategoria === "1" ? (
          <div className="inline px-2 py-1">
            
            <img src={primero} className="h-[20px] w-[20px] inline" alt="1ro" />
            {e?.posicionCategoria}
          </div>
        ) : e?.posicionCategoria === "2" ? (
          <div className="inline px-2 py-1">
            
            <img src={segundo} className="h-[20px] w-[20px] inline" alt="2do" />
            {e?.posicionCategoria}
          </div>
        ) : e?.posicionCategoria === "3" ? (
          <div className="inline px-2 py-1">
          
            <img src={tercero} className="h-[20px] w-[20px] inline" alt="3ro" />
            {e?.posicionCategoria}
          </div>
        ) : (
          <div className="inline px-2 py-1">{e?.posicionCategoria} </div>
        )}
      </td>
      <td className="px-2 py-4 text-sm whitespace-nowrap">{e?.categoria}</td>
      <td className="px-2 py-4 text-sm whitespace-nowrap">{e?.localidad}</td>
      <td className="px-2 py-4 text-sm whitespace-nowrap">{e?.evento.titulo}</td>
    </tr>
  );
};

export default Lista;
